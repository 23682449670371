import React, { useState, useEffect, Fragment } from "react";
import { useTranslation } from "react-i18next";
import {
  getCoreRowModel,
  useReactTable,
  flexRender,
  getSortedRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
} from "@tanstack/react-table";
import { useStorageState } from "react-use-storage-state";

const allSize = Number.MAX_SAFE_INTEGER;
// props:
//   - data
//   - columns
function TableSkeleton(props) {
  const [sorting, setSorting] = useState(props.sort);
  const [globalFilter, globalFilterSetter] = useState(null);
  const [showState, showStateSetter] = useStorageState("events-show", 10);
  const data = props.data;
  const columns = props.columns;
  const { t } = useTranslation();

  const table = useReactTable({
    data,
    columns,
    state: {
      sorting,
      columnVisibility: {},
      globalFilter,
    },
    onSortingChange: setSorting,
    onGlobalFilterChange: globalFilterSetter,
    globalFilterFn: "includesString",
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
  });

  useEffect(() => {
    table.setPageSize(showState);
  }, [showState, table]);

  const tableNavigation = (
    <>
      <div className="level smallerText tableNav">
        <div className="level-left">
          <div className="level-item">
            <button
              className="button is-small"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="icon is-small">
                <i className="fa fa-angle-double-left"></i>
              </span>
            </button>
            <button
              className="button is-small"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <span className="icon is-small">
                <i className="fa fa-angle-left"></i>
              </span>
            </button>
            <button
              className="button is-small"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span className="icon is-small">
                <i className="fa fa-angle-right"></i>
              </span>
            </button>
            <button
              className="button is-small"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
            >
              <span className="icon is-small">
                <i className="fa fa-angle-double-right"></i>
              </span>
            </button>
          </div>
          <div className="level-item">
            <p>
              Page <strong>{table.getState().pagination.pageIndex + 1}</strong>{" "}
              of <strong>{table.getPageCount()}</strong> (
              {table.getPrePaginationRowModel().rows.length} total rows)
            </p>
          </div>
          {/* <div className="level-item">
                    <div className="field">
                        <span>|&nbsp;Go to page:</span>
                        <input
                            type="number"
                            defaultValue={table.getState().pagination.pageIndex + 1}
                            onChange={e => {
                                const page = e.target.value ? Number(e.target.value) - 1 : 0
                                table.setPageIndex(page)
                            }}
                            className="input is-small"
                        />
                    </div>
                </div> */}
          <div className="level-item">
            <div className="control">
              <div className="select is-small">
                <select
                  value={table.getState().pagination.pageSize}
                  onChange={(e) => {
                    showStateSetter(Number(e.target.value));
                  }}
                >
                  {[10, 20, 30, 40, 50, allSize].map((pageSize) => (
                    <option key={pageSize} value={pageSize}>
                      Show {pageSize === allSize ? "All" : pageSize}
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
  //   const tableNavigation = (
  //     <>
  //       <div className="smallerText tableNav">
  //         <div>
  //           <button
  //             className="button is-small"
  //             onClick={() => table.setPageIndex(0)}
  //             disabled={!table.getCanPreviousPage()}
  //           >
  //             <span className="icon is-small">
  //               <i className="fa fa-angle-double-left"></i>
  //             </span>
  //           </button>
  //           <button
  //             className="button is-small"
  //             onClick={() => table.previousPage()}
  //             disabled={!table.getCanPreviousPage()}
  //           >
  //             <span className="icon is-small">
  //               <i className="fa fa-angle-left"></i>
  //             </span>
  //           </button>
  //           <button
  //             className="button is-small"
  //             onClick={() => table.nextPage()}
  //             disabled={!table.getCanNextPage()}
  //           >
  //             <span className="icon is-small">
  //               <i className="fa fa-angle-right"></i>
  //             </span>
  //           </button>
  //           <button
  //             className="button is-small"
  //             onClick={() => table.setPageIndex(table.getPageCount() - 1)}
  //             disabled={!table.getCanNextPage()}
  //           >
  //             <span className="icon is-small">
  //               <i className="fa fa-angle-double-right"></i>
  //             </span>
  //           </button>
  //         </div>
  //         <div>
  //           <p>
  //             Page <strong>{table.getState().pagination.pageIndex + 1}</strong> of{" "}
  //             <strong>{table.getPageCount()}</strong> (
  //             {table.getPrePaginationRowModel()?.rows?.length || 0} total rows)
  //           </p>
  //         </div>
  //         {/* <div className="level-item">
  //                     <div className="field">
  //                         <span>|&nbsp;Go to page:</span>
  //                         <input
  //                             type="number"
  //                             defaultValue={table.getState().pagination.pageIndex + 1}
  //                             onChange={e => {
  //                                 const page = e.target.value ? Number(e.target.value) - 1 : 0
  //                                 table.setPageIndex(page)
  //                             }}
  //                             className="input is-small"
  //                         />
  //                     </div>
  //                 </div> */}
  //         <div>
  //           <div className="control">
  //             <div className="select is-small">
  //               <select
  //                 value={table.getState().pagination.pageSize}
  //                 onChange={(e) => {
  //                   showStateSetter(Number(e.target.value));
  //                 }}
  //               >
  //                 {[10, 20, 30, 40, 50, allSize].map((pageSize) => (
  //                   <option key={pageSize} value={pageSize}>
  //                     Show {pageSize === allSize ? "All" : pageSize}
  //                   </option>
  //                 ))}
  //               </select>
  //             </div>
  //           </div>
  //         </div>
  //       </div>
  //     </>
  //   );

  return (
    <article>
      <div className="container">
        {/* {tableNavigation} */}
        <table className="table apTable">
          <thead>
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th key={header.id}>
                    {header.isPlaceholder ? null : (
                      <div
                        {...{
                          className: header.column.getCanSort()
                            ? "sortable"
                            : "",
                          onClick: header.column.getToggleSortingHandler(),
                        }}
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                        {{
                          asc: (
                            <Fragment>
                              &nbsp;<i className="fa fa-angle-up"></i>
                            </Fragment>
                          ),
                          desc: (
                            <Fragment>
                              &nbsp;<i className="fa fa-angle-down"></i>
                            </Fragment>
                          ),
                        }[header.column.getIsSorted()] ?? null}
                      </div>
                    )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody>
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id}>
                {row.getVisibleCells().map((cell) => (
                  <td key={cell.id}>
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {tableNavigation}
      </div>
    </article>
  );
}

export default TableSkeleton;
