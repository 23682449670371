import React from "react";
// import { useTranslation } from 'react-i18next';

function MyComponent(props) {
  // const { t } = useTranslation();
  // return (<div>{t("Welcome")}</div>);
  return <div></div>;
}

export default React.memo(MyComponent);
